<template>
    <div class="px-5">
        <div class="pb-4 qgreycard">
            <div class="quarolbl pb-2 pb-2">
                PROJECT TITLE
                <v-tooltip max-width="250" color="" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>The "Project Title" field specifies the title of your monitoring project. </span>
                </v-tooltip>
            </div>
            <v-text-field type="text" rounded filled v-model="project.title" hint="e.g. Optimization: KW-Research" persistent-hint></v-text-field>
        </div>

        <div class="pb-4 qgreycard">
            <div class="quarolbl pb-2">
                YOUR DOMAIN
                <v-tooltip max-width="250" color="" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Select the focus domain you want to monitor.</span>
                </v-tooltip>
            </div>
            <v-text-field :disabled="disabled" class="mb-2" v-model="inputDomain" rounded filled hint="e.g. https://www.xxxlutz.de" persistent-hint></v-text-field>
        </div>
        <div class="pb-4 qgreycard">
            <div class="quarolbl pb-2">
                LOCATION
                <v-tooltip max-width="250" color="" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>The "Location" field specifies the geographical location of the search results that will be analyzed. </span>
                </v-tooltip>
            </div>
            <v-select
                :items="textMap"
                :disabled="disabled"
                rounded
                v-model="project.langloc"
                item-text="location_text"
                item-value="location_code"
                prepend-inner-icon="mdi-map-marker"
                return-object
                filled
                attach>
            </v-select>
        </div>
        <v-row no-gutters>
            <v-col cols="6">
                <div class="qgreycard mt-0">
                    <div class="quarolbl pb-1">
                        DEVICE
                        <v-tooltip max-width="250" color="" top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>The "Device" field specifies the type of device the rankings will be fetched for.</span>
                        </v-tooltip>
                    </div>
                    <v-chip-group class="py-0" color="primary" column show-arrows v-model="deviceFilter" mandatory next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                        <v-chip v-for="(deviceType, i) in deviceTypes" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                            <span style="text-transform: capitalize"> {{ deviceType }}</span>
                        </v-chip>
                    </v-chip-group>
                    <!-- <v-radio-group mandatory :disabled="disabled" class="mt-0" v-model="project.device_type" row>
                <v-radio label="Mobile (recommended)" value="mobile"></v-radio>
                <v-radio label="Desktop" value="desktop"></v-radio>
            </v-radio-group> -->
                </div>
            </v-col>
            <v-col cols="6">
                <div class="qgreycard mt-0">
                    <div class="quarolbl pb-1">
                        Update Interval
                        <v-tooltip max-width="250" color="" top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>The "Update Interval" specifies if the rankings of your project will be updated every day, once a week or once a month.</span>
                        </v-tooltip>
                    </div>
                    <v-chip-group class="py-0" color="primary" column show-arrows v-model="intervalFilter" return-object mandatory next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                        <v-chip v-for="(interval, i) in intervalTexts" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                            <span style="text-transform: capitalize"> {{ interval }}</span>
                        </v-chip>
                    </v-chip-group>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import axios from "axios"
    export default {
        props: {
            project: Object
        },
        data() {
            return {
                inputDomain: "",
                deviceFilter: [0],
                intervalFilter: [0],
                disabled: false,
                deviceTypes: ["mobile", "desktop", "tablet"],
                intervalTexts: ["daily", "weekly", "monthly"],
                intervalTypes: [1, 7, 28],
                textMap: [
                    { location_text: "Germany", language_code: "de", location_code: 2276 },
                    { location_text: "Switzerland (de)", language_code: "de", location_code: 2756 },
                    { location_text: "Austria (de)", language_code: "de", location_code: 2040 },
                    { location_text: "United Kingdom", language_code: "en", location_code: 2826 },
                    { location_text: "United States", language_code: "en", location_code: 2840 },
                    { location_text: "Italy", language_code: "it", location_code: 2380 },
                    { location_text: "Hungary", language_code: "hu", location_code: 2348 },
                    { location_text: "Czechia", language_code: "cs", location_code: 2203 },
                    { location_text: "Croatia", language_code: "hr", location_code: 2191 },
                    { location_text: "Slovenia", language_code: "sl", location_code: 2705 },
                    { location_text: "Slovakia", language_code: "sk", location_code: 2703 },
                    { location_text: "Serbia", language_code: "sr", location_code: 2688 },
                    { location_text: "Romania", language_code: "ro", location_code: 2642 },
                    { location_text: "Bulgaria", language_code: "bg", location_code: 2100 },
                    { location_text: "Bosnia and Herzegovina", language_code: "hr", location_code: 2070 }
                ]
            }
        },

        methods: {
            async validateDomain() {
                // Regulärer Ausdruck zur Überprüfung einer gültigen Domain
                const domainRegex = /^((https?|ftp|smtp):\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(:[0-9]+)?(\/\S*)?$/

                if (domainRegex.test(this.project.domain)) {
                    // Die eingegebene Domain ist gültig
                    // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe gültig ist
                    console.log("Gültige Domain: " + this.project.domain)
                    try {
                        let testUrl = new URL(this.project.domain)
                        console.log(testUrl.host)
                        console.log(testUrl.hostname)
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    // Die eingegebene Domain ist ungültig
                    // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe ungültig ist
                    console.log("Ungültige Domain: " + this.project.domain)
                }
            },
            getIntervalText(interval) {
                return this.intervalTexts[this.intervals.indexOf(interval)]
            }
        },
        watch: {
            inputDomain(val) {
                const domainRegex = /^((https?|ftp|smtp):\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(:[0-9]+)?(\/\S*)?$/
                console.log(val)
                if (domainRegex.test(val)) {
                    // Die eingegebene Domain ist gültig
                    // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe gültig ist
                    console.log("Gültige Domain: " + val)
                    try {
                        let testUrl = new URL(val)
                        console.log(testUrl.host)
                        console.log(testUrl.hostname)
                        this.project.domain = testUrl.host
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    // Die eingegebene Domain ist ungültig
                    // Hier können Sie weitere Aktionen ausführen, wenn die Eingabe ungültig ist
                    console.log("Ungültige Domain: " + this.project.domain)
                }
            },
            deviceFilter(val) {
                this.project.device = this.deviceTypes[val]
            },
            intervalFilter(val) {
                this.project.interval = this.intervalTypes[val]
            }
        }
    }
</script>

<style></style>
